body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.entry-view-layout .result-content{
    margin-top: 24px;
}

.entry-view-layout .logo-wrapper {
    margin-bottom: 48px;
}
.ant-layout-footer {
    padding-top: 100px;
    color: #fff;
    background: #000;
}

.ant-layout-footer .footer-address .name {
    height: 52px;
    font-weight: 400;
    font-size: 14px;
}

.ant-layout-footer .footer-address .address {
    height: 24px;
    color: #7b7a7b;
    font-size: 10px;
}

.ant-layout-footer .footer-address .post {
    color: #7b7a7b;
    font-size: 10px;
}

.ant-layout-footer .footer-contact {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    font-size: 10px;
}

.ant-layout-footer .footer-contact a {
    color: #fff;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.global-seperate-line {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: #eee;
}
