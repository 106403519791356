ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.global-seperate-line {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: #eee;
}