.ant-layout-footer {
    padding-top: 100px;
    color: #fff;
    background: #000;
}

.ant-layout-footer .footer-address .name {
    height: 52px;
    font-weight: 400;
    font-size: 14px;
}

.ant-layout-footer .footer-address .address {
    height: 24px;
    color: #7b7a7b;
    font-size: 10px;
}

.ant-layout-footer .footer-address .post {
    color: #7b7a7b;
    font-size: 10px;
}

.ant-layout-footer .footer-contact {
    display: flex;
    justify-content: center;
    padding-top: 100px;
    font-size: 10px;
}

.ant-layout-footer .footer-contact a {
    color: #fff;
}